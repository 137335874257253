<script lang="ts" setup>
const modelValue = defineModel<string | number | null>()

const props = withDefaults(
  defineProps<{
    placeholder?: string
  }>(),
  { placeholder: undefined }
)

const emit = defineEmits<{
  (e: 'onCancelButtonClick'): void
  (e: 'onFocus'): void
  (e: 'onKeyupEnter'): void
  (e: 'onSearchButtonClick'): void
}>()

onMounted(() => {
  // $root.$on(
  //   'onSearchInputInHeaderFocus',
  //   () => {
  //     const input = $refs.input
  //
  //     if (input) {
  //       input.focus()
  //     }
  //   }
  // )
})

function onCancelButtonClick (): void {
  modelValue.value = null

  emit('onCancelButtonClick')
}
</script>

<template>
  <div class="search-input-group">
    <SvgIcon class="search-icon" name="outlined/search" />

    <input
      ref="input"
      v-model="modelValue"
      autocomplete="off"
      class="bg-white"
      :class="{ 'has-value': modelValue }"
      :placeholder="placeholder"
      type="text"
      @focus="emit('onFocus')"
      @keyup.enter="emit('onKeyupEnter')"
    >

    <div v-show="modelValue" class="right absolute flex h-full items-center">
      <SvgIcon class="cancel-icon" name="filled/cancel" @click="onCancelButtonClick" />

      <button class="text-white" @click="emit('onSearchButtonClick')">
        Найти
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'sass:color';

@use 'assets/css/variables';

.search-input-group {
  $icon-size: 24px;

  position: relative;

  input {
    appearance: none;
    border: 1px solid color.mix(variables.$main-gray-color, white, 60%);
    border-radius: 12px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 24px;
    outline: none;
    padding: 11px 16px 11px calc(16px + $icon-size + 8px);
    transition: border .25s ease-in-out;
    width: 100%;

    @media screen and (max-width: 480px) {
      font-size: 14px;
      padding: 7px 12px 7px 40px;
    }

    &:focus,
    &:hover {
      border-color: variables.$main-green-color;
    }

    &:placeholder-shown {
      text-overflow: ellipsis;
    }

    &.has-value {
      padding-right: 140px;
    }
  }

  svg.search-icon {
    color: color.mix(variables.$main-gray-color, white, 60%);
    font-size: $icon-size;
    left: 16px;
    pointer-events: none;
    position: absolute;
    top: 12px;

    @media screen and (max-width: 480px) {
      left: 8px;
      top: 8px;
    }
  }

  > .right {
    right: 0;
    top: 0;

    button {
      background: variables.$main-green-color;
      border: none;
      border-radius: 10px;
      box-sizing: border-box;
      cursor: pointer;
      font-family: inherit;
      font-size: 16px;
      font-weight: 500;
      line-height: 1em;
      margin-right: 4px;
      outline: none;
      padding: 12px 24px;
      transition: background-color .25s ease-in-out, box-shadow .25s ease-in-out;

      @media screen and (max-width: 480px) {
        margin-right: 0;
      }

      &:hover {
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, .25);
      }
    }

    svg.cancel-icon {
      color: color.mix(variables.$main-gray-color, white, 20%);
      cursor: pointer;
      font-size: $icon-size;
      margin-right: 8px;
      transition: color .25s ease-in-out;

      &:hover {
        color: color.mix(variables.$main-gray-color, white, 60%);
      }
    }
  }
}
</style>
